var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1250px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("FORMS.new_role"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',{staticStyle:{"max-height":"90%"}},[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.name'),"rules":_vm.nameRules,"id":_vm.dynamicID},model:{value:(_vm.formModel.name),callback:function ($$v) {_vm.$set(_vm.formModel, "name", $$v)},expression:"formModel.name"}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("FORMS.users"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":"","id":_vm.dynamicID},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.userHeaders,"items":_vm.formModel.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.christian_name1",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/settings/users/" + (item.id))}},[_vm._v(" "+_vm._s(item.christian_name1)+" ")])]}},{key:"item.surname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/settings/users/" + (item.id))}},[_vm._v(" "+_vm._s(item.surname)+" ")])]}},{key:"item.email",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":("mailto:" + value)}},[_vm._v(" "+_vm._s(value)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('p',{staticStyle:{"font-size":"1.25rem","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.permissions"))+" ")]),_c('v-expansion-panels',{attrs:{"multiple":""}},_vm._l((_vm.permissionModuleNames),function(moduleName,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("PERMISSIONS." + moduleName))+" ")]),_c('v-expansion-panel-content',_vm._l((_vm.permissions),function(item,i){return _c('span',{key:i},[(
                        item.name.includes(moduleName) &&
                        item.name != 'sales.salesOrder.assign.group'
                      )?_c('v-switch',{key:i + 'perm',attrs:{"disabled":_vm.formModel.id == 2,"label":_vm.$t('PERMISSIONS.' + item.name.replaceAll('.', '_')),"value":item},on:{"change":function($event){return _vm.handleSwitcher(item)}},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}}):_vm._e()],1)}),0)],1)}),1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }